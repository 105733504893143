.p-dataview {
    .p-paginator-top {
        border-width: $tableTopPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-paginator-bottom {
        border-width: $tableBottomPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-dataview-header {
        padding: $tableHeaderPadding;

        font-weight: $tableHeaderFontWeight;
        color: $tableHeaderTextColor;

        background: $tableHeaderBg;
        border: $tableHeaderBorder;
        border-width: $tableHeaderBorderWidth;
    }

    .p-dataview-content {
        padding: $dataViewContentPadding;
        color: $tableBodyRowTextColor;
        background: $tableBodyRowBg;
        border: $dataViewContentBorder;
    }

    &.p-dataview-list {
        .p-dataview-content {
            > .p-grid > div {
                border: $dataViewListItemBorder;
                border-width: $dataViewListItemBorderWidth;
            }
        }
    }

    .p-dataview-footer {
        padding: $tableFooterPadding;

        font-weight: $tableFooterFontWeight;
        color: $tableFooterTextColor;

        background: $tableFooterBg;
        border: $tableFooterBorder;
        border-width: $tableFooterBorderWidth;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-dataview-loading-icon {
        font-size: $loadingIconFontSize;
    }

    .p-dataview-emptymessage {
        padding: $panelContentPadding;
    }
}
