.p-splitter {
    color: $panelContentTextColor;
    background: $panelContentBg;
    border: $panelContentBorder;
    border-radius: $borderRadius;

    .p-splitter-gutter {
        background: $splitterGutterBg;
        transition: $actionIconTransition;

        .p-splitter-gutter-handle {
            background: $splitterGutterHandleBg;
        }
    }

    .p-splitter-gutter-resizing {
        background: $splitterGutterHandleBg;
    }
}