.p-terminal {
	padding: $panelContentPadding;
	color: $panelContentTextColor;
	background: $panelContentBg;
	border: $panelContentBorder;

	.p-terminal-input {
		font-family: $fontFamily;
		font-size: $fontSize;
	}
}
