.p-datatable {
  .p-paginator-top {
    border-width: $tableTopPaginatorBorderWidth;
    border-radius: 0;
  }

  .p-paginator-bottom {
    border-width: $tableBottomPaginatorBorderWidth;
    border-radius: 0;
  }

  .p-datatable-table {
    overflow: hidden;
    background: white;
  }

  .p-datatable-header {
    padding: $tableHeaderPadding;

    font-weight: $tableHeaderFontWeight;
    color: $tableHeaderTextColor;

    background: $tableHeaderBg;
    border: $tableHeaderBorder;
    border-width: $tableHeaderBorderWidth;
  }

  .p-datatable-footer {
    padding: $tableFooterPadding;

    font-weight: $tableFooterFontWeight;
    color: $tableFooterTextColor;

    background: $tableFooterBg;
    border: $tableFooterBorder;
    border-width: $tableFooterBorderWidth;
  }

  .p-datatable-thead > tr > th {
    padding: $tableHeaderCellPadding;

    font-weight: $tableHeaderCellFontWeight;
    text-align: $tableCellContentAlignment;
    color: $tableHeaderCellTextColor;

    background: $tableHeaderCellBg;
    border: $tableHeaderCellBorder;
    border-width: $tableHeaderCellBorderWidth;

    transition: $listItemTransition;
  }

  .p-datatable-tfoot > tr > td {
    padding: $tableFooterCellPadding;

    font-weight: $tableFooterCellFontWeight;
    text-align: $tableCellContentAlignment;
    color: $tableFooterCellTextColor;

    background: $tableFooterCellBg;
    border: $tableFooterCellBorder;
    border-width: $tableFooterCellBorderWidth;
  }

  .p-sortable-column {
    &.p-sortable-column-icon-wrapper {
      color: $actionIconColor;
      background: currentcolor;

      &:hover {
        color: $actionIconHoverColor;
        background: $actionIconHoverBg;
        border-color: $actionIconHoverBorderColor;
      }

      sortamountupalticon {
        background: linear-gradient(
          180deg,
          $primaryColor 0%,
          $primaryColor 50%,
          $tableHeaderCellIconColor 50%,
          $tableHeaderCellIconColor 100%
        );
      }

      sortamountdownicon {
        background: linear-gradient(
          180deg,
          $tableHeaderCellIconColor 0%,
          $tableHeaderCellIconColor 50%,
          $primaryColor 50%,
          $primaryColor 100%
        );
      }
    }

    .p-sortable-column-icon {
      margin-left: 0;
      color: transparent !important;
    }

    .p-sortable-column-badge {
      min-width: $tableSortableColumnBadgeSize;
      height: $tableSortableColumnBadgeSize;
      margin-left: $inlineSpacing;

      line-height: $tableSortableColumnBadgeSize;
      color: $highlightTextColor;

      background: $highlightBg;
      border-radius: 50%;
    }

    &:not(.p-highlight):hover {
      color: $tableHeaderCellTextHoverColor;
      background: $tableHeaderCellHoverBg;

      .p-sortable-column-icon {
        color: $tableHeaderCellIconHoverColor;
      }
    }

    &.p-highlight {
      color: $tableHeaderCellHighlightTextColor;
      background: $tableHeaderCellHighlightBg;

      .p-sortable-column-icon {
        color: $tableHeaderCellHighlightTextColor;
      }

      &:hover {
        color: $tableHeaderCellHighlightTextColor;
        background: $tableHeaderCellHighlightHoverBg;

        .p-sortable-column-icon {
          color: $tableHeaderCellHighlightTextColor;
        }
      }
    }

    &:focus {
      outline: 0 none;
      box-shadow: $inputListItemFocusShadow;
    }
  }

  .p-datatable-tbody {
    > tr {
      color: $tableBodyRowTextColor;
      background: $tableBodyRowBg;
      transition: $listItemTransition;

      > td {
        padding: $tableBodyCellPadding;
        text-align: $tableCellContentAlignment;
        border: $tableBodyCellBorder;
        border-width: $tableBodyCellBorderWidth;

        .p-row-toggler,
        .p-row-editor-init,
        .p-row-editor-save,
        .p-row-editor-cancel {
          @include action-icon;
        }

        .p-row-editor-save {
          margin-right: $inlineSpacing;
        }
      }

      &:focus {
        outline: 0.15rem solid $focusOutlineColor;
        outline-offset: 0.15rem;
      }

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }

      &.p-datatable-dragpoint-top > td {
        box-shadow: inset 0 2px 0 0 $highlightBg;
      }

      &.p-datatable-dragpoint-bottom > td {
        box-shadow: inset 0 -2px 0 0 $highlightBg;
      }
    }
  }

  &.p-datatable-hoverable-rows {
    .p-datatable-tbody > tr:not(.p-highlight):hover {
      color: $tableBodyRowTextHoverColor;
      background: $tableBodyRowHoverBg;
    }
  }

  .p-column-resizer-helper {
    background: $tableResizerHelperBg;
  }

  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    background: $panelHeaderBg;
  }

  &.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table,
  &.p-datatable-scrollable
    > .p-datatable-wrapper
    > .p-scroller-viewport
    > .p-scroller
    > .p-datatable-table {
    > .p-datatable-thead,
    > .p-datatable-tfoot {
      background-color: $tableHeaderCellBg;
    }
  }

  .p-datatable-loading-icon {
    font-size: $loadingIconFontSize;
  }

  &.p-datatable-gridlines {
    .p-datatable-table {
      outline: $tableBorder;
      outline-offset: -1px;
    }

    .p-datatable-header {
      border-width: 1px 1px 0;
    }

    .p-datatable-footer {
      border-width: 0 1px 1px;
    }

    .p-paginator-top {
      border-width: 0 1px;
    }

    .p-paginator-bottom {
      border-width: 0;
    }

    .p-datatable-thead {
      > tr {
        > th {
          border-width: 1px 0 1px 1px;

          &:last-child {
            border-width: 1px;
          }
        }
      }
    }

    .p-datatable-tbody {
      > tr {
        > td {
          border-width: 1px 0 0 1px;

          &:last-child {
            border-width: 1px 1px 0;
          }
        }

        &:last-child {
          > td {
            border-width: 1px 0 1px 1px;

            &:last-child {
              border-width: 1px;
            }
          }
        }
      }
    }

    .p-datatable-tfoot {
      > tr {
        > td {
          border-width: 1px 0 1px 1px;

          &:last-child {
            border-width: 1px;
          }
        }
      }
    }

    .p-datatable-thead + .p-datatable-tfoot {
      > tr {
        > td {
          border-width: 0 0 1px 1px;

          &:last-child {
            border-width: 0 1px 1px;
          }
        }
      }
    }

    &:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody {
      > tr {
        > td {
          border-width: 0 0 1px 1px;

          &:last-child {
            border-width: 0 1px 1px;
          }
        }
      }
    }

    &:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody {
      > tr {
        &:last-child {
          > td {
            border-width: 0 0 0 1px;

            &:last-child {
              border-width: 0 1px;
            }
          }
        }
      }
    }
  }

  &.p-datatable-striped {
    .p-datatable-tbody {
      > tr:nth-child(even) {
        background: $tableBodyRowEvenBg;

        &.p-highlight {
          color: $highlightTextColor;
          background: $highlightBg;

          .p-row-toggler {
            color: $highlightTextColor;

            &:hover {
              color: $highlightTextColor;
            }
          }
        }
      }
    }
  }

  &.p-datatable-sm {
    .p-datatable-header {
      @include scaledPadding($tableHeaderPadding, $tableScaleSM);
    }

    .p-datatable-thead > tr > th {
      @include scaledPadding($tableHeaderCellPadding, $tableScaleSM);
    }

    .p-datatable-tbody > tr > td {
      @include scaledPadding($tableBodyCellPadding, $tableScaleSM);
    }

    .p-datatable-tfoot > tr > td {
      @include scaledPadding($tableFooterPadding, $tableScaleSM);
    }

    .p-datatable-footer {
      @include scaledPadding($tableFooterPadding, $tableScaleSM);
    }
  }

  &.p-datatable-lg {
    .p-datatable-header {
      @include scaledPadding($tableHeaderPadding, $tableScaleLG);
    }

    .p-datatable-thead > tr > th {
      @include scaledPadding($tableHeaderCellPadding, $tableScaleLG);
    }

    .p-datatable-tbody > tr > td {
      @include scaledPadding($tableBodyCellPadding, $tableScaleLG);
    }

    .p-datatable-tfoot > tr > td {
      @include scaledPadding($tableFooterPadding, $tableScaleLG);
    }

    .p-datatable-footer {
      @include scaledPadding($tableFooterPadding, $tableScaleLG);
    }
  }
}
