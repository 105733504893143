.p-contextmenu {
    width: $menuWidth;
    padding: $verticalMenuPadding;

    color: $menuTextColor;

    background: $overlayMenuBg;
    border: $overlayMenuBorder;
    border-radius: $borderRadius;
    box-shadow: $overlayMenuShadow;

    .p-contextmenu-root-list {
        outline: 0 none;
    }

    .p-submenu-list {
        padding: $verticalMenuPadding;

        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        border-radius: $borderRadius;
        box-shadow: $overlayMenuShadow;
    }

    .p-menuitem {
        @include menuitem;
    }

    .p-menuitem-separator {
        margin: $menuSeparatorMargin;
        border-top: $divider;
    }

    .p-submenu-icon {
        font-size: $menuitemSubmenuIconFontSize;

        &.p-icon {
            width: $menuitemSubmenuIconFontSize;
            height: $menuitemSubmenuIconFontSize;
        }
    }
}
