p-calendar.ng-dirty.ng-invalid > .p-calendar {
  @include invalid-input;
  border: 1px solid red;
}

.p-calendar {
  width: $inputWidth;
  margin-top: $inputMarginTop;
  border: $inputBorder;
  border-radius: $borderRadius;

  .p-inputtext {
    border: none;
  }

  .p-datepicker-trigger {
    width: auto !important;
    padding: $datepickerTriggerPadding !important;

    color: $datepickerTriggerColor !important;

    background: $datepickerTriggerBg !important;
    border: none !important;
  }

  .p-datepicker {
    min-width: fit-content !important;
  }
}

.p-datepicker {
  padding: $calendarPadding;

  color: $calendarTextColor;

  background: $calendarInlineBg;
  border: $calendarBorder;
  border-radius: $borderRadius;

  &:not(.p-datepicker-inline) {
    background: $calendarBg;
    border: $calendarOverlayBorder;
    box-shadow: $inputOverlayShadow;

    .p-datepicker-header {
      background: $calendarHeaderBg;
    }
  }

  .p-datepicker-header {
    margin: $inputListHeaderMargin;
    padding: $calendarHeaderPadding;

    font-weight: $calendarHeaderFontWeight;
    color: $calendarHeaderTextColor;

    background: $calendarInlineHeaderBg;
    border-bottom: $calendarHeaderBorder;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    .p-datepicker-prev,
    .p-datepicker-next {
      @include action-icon;
    }

    .p-datepicker-title {
      line-height: 1.57;

      .p-datepicker-year,
      .p-datepicker-month {
        font-weight: $calendarHeaderFontWeight;
        color: $calendarHeaderTextColor;
        transition: $actionIconTransition;

        &:enabled:hover {
          color: $calendarMonthYearHeaderHoverTextColor;
        }
      }

      .p-datepicker-month {
        margin-right: $inlineSpacing;
      }
    }
  }

  table {
    display: block;
    margin: $calendarTableMargin;
    padding: 0 1.2rem;
    font-size: $fontSize;

    th {
      padding: $calendarHeaderCellPadding;

      > span {
        width: $calendarCellDateWidth;
        height: $calendarCellDateHeight;
      }
    }

    td {
      padding: $calendarCellDatePadding;

      > span {
        width: $calendarCellDateWidth;
        height: $calendarCellDateHeight;

        border: $calendarCellDateBorder;
        border-radius: $calendarCellDateBorderRadius;

        transition: $listItemTransition;

        &.p-highlight {
          color: $calendarHighlightTextColor;
          background: $calendarHighlightBg;
        }

        &:focus {
          @include focused;
        }
      }

      &.p-datepicker-today {
        > span {
          color: $calendarCellDateTodayTextColor;
          background: $calendarCellDateTodayBg;
          border-color: $calendarCellDateTodayBorderColor;

          &.p-highlight {
            color: $calendarHighlightTextColor;
            background: $calendarHighlightBg;
          }
        }
      }
    }
  }

  .p-datepicker-buttonbar {
    padding: $calendarButtonBarPadding;
    border-top: $divider;

    .p-button {
      width: auto;
    }
  }

  .p-timepicker {
    padding: $calendarTimePickerPadding;
    border-top: $divider;

    button {
      @include action-icon;

      &:last-child {
        margin-top: 0.2em;
      }
    }

    span {
      font-size: $calendarTimePickerTimeFontSize;
    }

    > div {
      padding: $calendarTimePickerElementPadding;
    }
  }

  &.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none;
    }
  }

  .p-monthpicker {
    margin: 0;

    .p-monthpicker-month {
      padding: 2.2rem 1rem;
      border-radius: $borderRadius;
      transition: $listItemTransition;

      &.p-highlight {
        color: $calendarHighlightTextColor;
        background: $calendarHighlightBg;
      }
    }
  }

  .p-yearpicker {
    margin: 0;

    .p-yearpicker-year {
      padding: 2.2rem 1rem;
      border-radius: $borderRadius;
      transition: $listItemTransition;

      &.p-highlight {
        color: $calendarHighlightTextColor;
        background: $calendarHighlightBg;
      }
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      padding: 0 $calendarPadding 0 $calendarPadding;

      &:not(:first-child) {
        .p-datepicker-header {
          padding-left: 2.4rem;
        }
      }

      &:first-child {
        padding-left: 0;
        border-left: 0 none;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .p-datepicker-other-month {
    color: rgb(0 0 0 / 25%);
  }

  &:not(.p-disabled) {
    table {
      td {
        span:not(.p-highlight, .p-disabled) {
          &:hover {
            color: $calendarCellDateHoverColor;
            background: $calendarCellDateHoverBg;
          }

          &:focus {
            @include focused;
          }
        }
      }
    }

    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          &:not(.p-highlight):hover {
            color: white;
            background: $calendarCellDateHoverBg;
          }

          &:focus {
            @include focused;
          }
        }
      }
    }

    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          &:not(.p-highlight):hover {
            color: white;
            background: $calendarCellDateHoverBg;
          }

          &:focus {
            @include focused;
          }
        }
      }
    }
  }
}

.p-datepicker-next-icon {
  @extend .icon;
  @extend .icon--chevron-right;
}

.p-datepicker-prev-icon {
  @extend .icon;
  @extend .icon--chevron-left;
}

p-calendar.p-calendar-clearable {
  .p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
  }

  .p-calendar-clear-icon {
    right: nth($inputPadding, 2);
    color: $inputIconColor;
  }
}

p-calendar.p-calendar-clearable.p-calendar-w-btn {
  .p-calendar-clear-icon {
    right: $buttonIconOnlyWidth + nth($inputPadding, 2);
    color: $inputIconColor;
  }
}

@media screen and (max-width: $calendarBreakpoint) {
  .p-datepicker {
    table {
      th,
      td {
        padding: $calendarCellDatePaddingSM;
      }
    }
  }
}
