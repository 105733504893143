/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: #eaeaf6;

/// Background of a modal (custom)
/// @group overlay
$modalContentBg: white;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: none;

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: white;

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 1px solid #dee2e6;

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: $panelHeaderTextColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 600;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 1.25rem;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 1.6rem 2.4rem;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 2rem 2.4rem;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 1px solid #dee2e6;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 2.4rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: white;

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: $textColor;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: $inputPadding;

/// Shadow for a tooltip (custom)
/// @group form
$tooltipShadow: 0 3px 6px 0 rgb(0 0 0 / 7%);
