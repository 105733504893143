.p-panelmenu {
    .p-panelmenu-header {
        outline: 0 none;

        .p-panelmenu-header-content {
            color: $accordionHeaderTextColor;

            background: $accordionHeaderBg;
            border: $accordionHeaderBorder;
            border-radius: $borderRadius;

            transition: $listItemTransition;

            .p-panelmenu-header-action {
                padding: $accordionHeaderPadding;
                font-weight: $accordionHeaderFontWeight;
                color: $accordionHeaderTextColor;

                .p-submenu-icon {
                    margin-right: $inlineSpacing;
                }

                .p-menuitem-icon {
                    margin-right: $inlineSpacing;
                }
            }
        }

        &:not(.p-disabled) {
            &:focus {
                .p-panelmenu-header-content {
                    @include focused-inset;
                }
            }
        }

        &:not(.p-highlight, .p-disabled):hover {
            .p-panelmenu-header-content {
                color: $accordionHeaderTextHoverColor;
                background: $accordionHeaderHoverBg;
                border-color: $accordionHeaderHoverBorderColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-panelmenu-header-content {
                margin-bottom: 0;

                color: $accordionHeaderTextActiveColor;

                background: $accordionHeaderActiveBg;
                border-color: $accordionHeaderActiveBorderColor;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:hover {
                .p-panelmenu-header-content {
                    color: $accordionHeaderTextActiveHoverColor;
                    background: $accordionHeaderActiveHoverBg;
                    border-color: $accordionHeaderActiveHoverBorderColor;
                }
            }
        }
    }

    .p-panelmenu-content {
        padding: $verticalMenuPadding;

        color: $accordionContentTextColor;

        background: $accordionContentBg;
        border: $accordionContentBorder;
        border-top: 0;
        border-radius: 0 0 $borderRadius $borderRadius;

        .p-panelmenu-root-list {
            outline: 0 none;
        }

        .p-menuitem {
            @include menuitem;

            .p-menuitem-content {
                .p-menuitem-link { 
                    .p-submenu-icon {
                        margin-right: $inlineSpacing;
                    }
                }
            }
        }

        .p-menuitem-separator {
            margin: $menuSeparatorMargin;
            border-top: $divider;
        }

        .p-submenu-list:not(.p-panelmenu-root-list) {
            padding: $treeNodeChildrenPadding;
        }
    }

    .p-panelmenu-panel {
        margin-bottom: $accordionSpacing;

        @if $accordionSpacing == 0 {
            .p-panelmenu-header {
                .p-panelmenu-header-content {
                    border-radius: 0;
                }
            }

            .p-panelmenu-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-panelmenu-header {
                    .p-panelmenu-header-content {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight, .p-disabled):hover,
                    &:not(.p-disabled).p-highlight:hover {
                        .p-panelmenu-header-content {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-panelmenu-header {
                    .p-panelmenu-header-content {
                        border-top-left-radius: $borderRadius;
                        border-top-right-radius: $borderRadius;
                    }
                }
            }

            &:last-child {
                .p-panelmenu-header:not(.p-highlight) {
                    .p-panelmenu-header-content {
                        border-bottom-left-radius: $borderRadius;
                        border-bottom-right-radius: $borderRadius;
                    }
                }

                .p-panelmenu-content {
                    border-bottom-left-radius: $borderRadius;
                    border-bottom-right-radius: $borderRadius;
                }
            }
        } 
    }
}
