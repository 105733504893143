.p-progressbar {
    height: $progressBarHeight;
    background: $progressBarBg;
    border: $progressBarBorder;
    border-radius: $borderRadius;

    .p-progressbar-value {
        margin: 0;
        background: $progressBarValueBg;
        border: 0 none;
    }

    .p-progressbar-label {
        line-height: $progressBarHeight;
        color: $progressBarValueTextColor;
    }
}
