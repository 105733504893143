.p-menubar {
    padding: $horizontalMenuPadding;

    color: $horizontalMenuTextColor;

    background: $horizontalMenuBg;
    border: $horizontalMenuBorder;
    border-radius: $borderRadius;

    .p-menubar-root-list {
        outline: 0 none;

        > .p-menuitem {
            @include horizontal-rootmenuitem;
        }
    }

    .p-menuitem {
        @include menuitem;
    }

    .p-submenu-list {
        width: $menuWidth;
        padding: $verticalMenuPadding;

        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;

        .p-menuitem-separator {
            margin: $menuSeparatorMargin;
            border-top: $divider;
        }

        .p-submenu-icon {
            font-size: $menuitemSubmenuIconFontSize;
        }
    }
}

@media screen and (width <= 960px) {
    .p-menubar {
        position: relative;

        .p-menubar-button {
            display: flex;
            width: $actionIconWidth;
            height: $actionIconHeight;

            color: $horizontalMenuRootMenuitemIconColor;

            border-radius: $actionIconBorderRadius;

            transition: $actionIconTransition;

            &:hover {
                color: $horizontalMenuRootMenuitemIconHoverColor;
                background: $horizontalMenuRootMenuitemHoverBg;
            }

            &:focus {
                @include focused;
            }
        }

        .p-menubar-root-list {

            @include nested-submenu-indents(nth($menuitemPadding, 1), 2, 10);

            position: absolute;

            display: none;
            width: 100%;
            padding: $verticalMenuPadding;

            background: $overlayMenuBg;
            border: $overlayMenuBorder;
            box-shadow: $overlayMenuShadow;

            .p-menuitem-separator {
                margin: $menuSeparatorMargin;
                border-top: $divider;
            }

            .p-submenu-icon {
                font-size: $menuitemSubmenuIconFontSize;
            }

            .p-menuitem {
                position: static;
                width: 100%;

                .p-menuitem-content {
                    .p-menuitem-link {
                        .p-submenu-icon {
                            margin-left: auto;
                            transition: transform $transitionDuration;
                        }
                    }
                }


                &.p-menuitem-active {
                    > .p-menuitem-content {
                        > .p-menuitem-link {
                            > .p-submenu-icon {
                                transform: rotate(-180deg);
                            }
                        }
                    }
                }
            }

            .p-submenu-list {
                position: static;
                width: 100%;
                border: 0 none;
                box-shadow: none;

                .p-submenu-icon {
                    transform: rotate(90deg);
                    transition: transform $transitionDuration;
                }

                .p-menuitem-active {
                    > .p-menuitem-content {
                        > .p-menuitem-link {
                            > .p-submenu-icon {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
            }
        }

        &.p-menubar-mobile-active .p-menubar-root-list {
            top: 100%;
            left: 0;
            z-index: 1;

            display: flex;
            flex-direction: column;
        }
    }
}
