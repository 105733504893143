.p-column-filter-row {
  .p-column-filter-menu-button,
  .p-column-filter-clear-button {
    margin-left: $inlineSpacing;
  }
}

.p-column-filter-menu-button {
  width: $actionIconWidth;
  height: $actionIconHeight;

  line-height: 0;
  color: $actionIconColor;

  background: $actionIconBg;
  border: $actionIconBorder;
  border-radius: $actionIconBorderRadius;

  transition: $actionIconTransition;

  &:hover {
    color: $actionIconHoverColor;
    background: $actionIconHoverBg;
    border-color: $actionIconHoverBorderColor;
  }

  &.p-column-filter-menu-button-open,
  &.p-column-filter-menu-button-open:hover {
    color: $actionIconHoverColor;
    background: $actionIconHoverBg;
  }

  &.p-column-filter-menu-button-active,
  &.p-column-filter-menu-button-active:hover {
    color: $highlightTextColor;
    background: $highlightBg;
  }

  &:focus {
    @include focused;
  }
}

.p-column-filter-clear-button {
  width: $actionIconWidth;
  height: $actionIconHeight;

  color: $actionIconColor;

  background: $actionIconBg;
  border: $actionIconBorder;
  border-radius: $actionIconBorderRadius;

  transition: $actionIconTransition;

  &:hover {
    color: $actionIconHoverColor;
    background: $actionIconHoverBg;
    border-color: $actionIconHoverBorderColor;
  }

  &:focus {
    @include focused;
  }
}

.p-column-filter-overlay {
  min-width: $menuWidth;

  color: $inputListTextColor;

  background: $inputOverlayBg;
  border: $inputOverlayBorder;
  border-radius: $borderRadius;
  box-shadow: $inputOverlayShadow;

  .p-column-filter-row-items {
    padding: $inputListPadding;

    .p-column-filter-row-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;

      color: $inputListItemTextColor;

      background: $inputListItemBg;
      border: $inputListItemBorder;
      border-radius: $inputListItemBorderRadius;

      transition: $listItemTransition;

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;
      }

      &:not(.p-highlight, .p-disabled):hover {
        color: $inputListItemTextHoverColor;
        background: $inputListItemHoverBg;
      }

      &:focus {
        @include focused-listitem;
      }
    }

    .p-column-filter-separator {
      margin: $menuSeparatorMargin;
      border-top: $divider;
    }
  }
}

.p-column-filter-overlay-menu {
  .p-column-filter-operator {
    margin: $inputListHeaderMargin;
    padding: $inputListHeaderPadding;

    color: $inputListHeaderTextColor;

    background: $inputOverlayHeaderBg;
    border-bottom: $inputListHeaderBorder;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }

  .p-column-filter-constraint {
    padding: $panelContentPadding;
    border-bottom: $divider;

    .p-column-filter-matchmode-dropdown {
      margin-bottom: $inlineSpacing;
    }

    .p-column-filter-remove-button {
      margin-top: $inlineSpacing;
    }

    &:last-child {
      border-bottom: 0 none;
    }
  }

  .p-column-filter-add-rule {
    padding: $panelFooterPadding;
  }

  .p-column-filter-buttonbar {
    gap: 2rem;
    padding: $filterButtonbarPadding;
    border-top: thin solid rgb(0 0 0 / 15%);

    [ng-reflect-label='Clear'] {
      @extend .p-ghost-button;
    }
  }
}
