.p-fieldset {
    margin: 0;
    padding: 0;

    color: $panelContentTextColor;

    background: $cardContentBg;
    border: none;
    border-radius: $borderRadius;

    .p-fieldset-legend {
        width: 100%;
        padding: $fieldsetPadding;

        font-size: $legendFontSize;
        font-weight: $legendFontWeight;
        line-height: $legendLineHeight;
        color: $legendColor;

        background: $cardContentBg;
        border-radius: $borderRadius;

        span {
            justify-content: flex-start;
        }
    }

    &.p-fieldset-toggleable {
        .p-fieldset-legend {
            padding: 0;
            transition: $actionIconTransition;

            a {
                padding: $panelHeaderPadding;
                color: $panelHeaderTextColor;
                border-radius: $borderRadius;
                transition: $listItemTransition;

                .p-fieldset-toggler {
                    margin-right: $inlineSpacing;
                }

                &:focus {
                    @include focused;
                }
            }

            &:hover {
                color: $panelHeaderTextHoverColor;
                background: $panelHeaderHoverBg;
                border-color: $panelHeaderHoverBorderColor;
            }
        }
    }

    .p-fieldset-content {
        padding: 0 $fieldsetPadding $fieldsetPadding;
    }
}
