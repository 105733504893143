@use 'sass:math';

.p-chips {
    .p-chips-multiple-container {
        gap: $inlineSpacing;
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);

        &:not(.p-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input;
        }

        .p-chips-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            color: $highlightTextColor;
            background: $highlightBg;
            border-radius: $borderRadius;

            .p-chips-token-icon {
                margin-left: $inlineSpacing;
            }
        }

        .p-chips-input-token {
            padding: math.div(nth($inputPadding, 1), 2) 0;
                
            input {
                margin: 0;
                padding: 0;

                font-family: $fontFamily;
                font-size: $fontSize;
                color: $textColor;
            }
        }
    }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    @include invalid-input;
}

p-chips.p-chips-clearable {
    .p-inputtext {
        padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-chips-clear-icon {
        right: nth($inputPadding, 2);
        color: $inputIconColor;
    } 
}
