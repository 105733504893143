// Theme Specific Variables
$primaryColor: #722ed1;
$primaryDarkColor: #531dab;
$primaryDarkerColor: #391085;
$primaryTextColor: white;
$secondaryColor: #26336e;
$secondaryTextColor: white;
$magnolia: #f5f5fd;
$lavender: #F9F0FF;
$silver: #bbb;
$seashell: #f0f0f0;
$salmon: #FF7875;
$link-water: #D6DBF5;
$error: #FF4D4F;

$colors: (
  'primary': $primaryColor,
  'seondary': $secondaryColor
);

$icons: (
  'home',
  'document',
  'list',
  'terminal',
  'cog',
  'arrow-left',
  'filter',
  'sort',
  'angle-down',
  'calendar',
  'checkmark',
  'checkmark2',
  'tooltip',
  'edit',
  'search',
  'eye',
  'close',
  'chevron-left',
  'chevron-right',
  'user',
  'plus',
  'min',
  'trash',
  'reactivate',
  'update',
  'reject',
  'csv-file',
  'xml-file',
  'xlsx-file',
  'zip-file',
  'logout',
  'external-link',
  'envelope'
);
$primary-icons: ('document', 'terminal');

$h1-font-size: 3.8rem;
$h2-font-size: 3rem;
$h3-font-size: 2.4rem;
$h4-font-size: 2rem;
$h5-font-size: 1.6rem;
$h6-font-size: 1.4rem;

$h-font-sizes: (
  $h1-font-size,
  $h2-font-size,
  $h3-font-size,
  $h4-font-size,
  $h5-font-size,
  $h6-font-size
);

$h1-line-height: 1.2;
$h2-line-height: 1.26;
$h3-line-height: 1.33;
$h4-line-height: 1.4;
$h5-line-height: 1.5;
$h6-line-height: 1.5;

$h-line-heights: (
  $h1-line-height,
  $h2-line-height,
  $h3-line-height,
  $h4-line-height,
  $h5-line-height,
  $h6-line-height
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/data';
@import './variables/overlay';
@import './variables/message';
@import './variables/menu';
@import './variables/media';
@import './variables/misc';

:root {
  --surface-a: white;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: white;
  --surface-f: white;
  --text-color: rgb(0 0 0 / 88%);
  --text-color-secondary: $secondaryColor;
  --primary-color: #{$primaryColor};
  --primary-color-text: #{$primaryTextColor};
  --font-family: 'Lato', sans-serif;
  --surface-0: white;
  --surface-50: #fafafa;
  --surface-100: whitesmoke;
  --surface-200: #eee;
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-800: #424242;
  --surface-900: #212121;
  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --surface-ground: #f8f9fa;
  --surface-section: white;
  --surface-card: white;
  --surface-overlay: white;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};

  color-scheme: light;
}
