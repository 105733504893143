.p-toolbar {
    gap: $toolbarGap;
    padding: $toolbarPadding;

    background: $toolbarBg;
    border: $toolbarBorder;
    border-radius: $borderRadius;

    .p-toolbar-separator {
        margin: 0 $inlineSpacing;
    }
}
