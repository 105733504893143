/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex.css";

@import './sass/theme/theme';
@import "primeng/resources/primeng.css";

html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-size: 62.50%; // 1rem = 10px
}

body {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;

  font-family: var(--font-family);
}
