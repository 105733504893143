.p-menu {
    width: $menuWidth;
    padding: $verticalMenuPadding;

    color: $menuTextColor;

    background: $menuBg;
    border: $menuBorder;
    border-radius: $borderRadius;

    .p-menuitem {
        @include menuitem;
    }

    &.p-menu-overlay {
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;

        font-weight: $submenuHeaderFontWeight;
        color: $submenuHeaderTextColor;

        background: $submenuHeaderBg;
        border-top-left-radius: $submenuHeaderBorderRadius;
        border-top-right-radius: $submenuHeaderBorderRadius;
    }

    .p-menuitem-separator {
        margin: $menuSeparatorMargin;
        border-top: $divider;
    }

    .p-menuitem-badge {
        @include menuitem-badge;
    }
}
