.p-galleria {
    .p-galleria-close {
        width: $galleriaCloseIconWidth;
        height: $galleriaCloseIconHeight;
        margin: $galleriaCloseIconMargin;

        color: $galleriaCloseIconColor;

        background: $galleriaCloseIconBg;
        border-radius: $galleriaCloseIconBorderRadius;

        transition: $actionIconTransition;

        .p-galleria-close-icon {
            font-size: $galleriaCloseIconFontSize;
        }

        .p-icon-wrapper {
            .p-icon {
                width: $galleriaCloseIconFontSize;
                height: $galleriaCloseIconFontSize;
            }
        }

        &:hover {
            color: $galleriaCloseIconHoverColor;
            background: $galleriaCloseIconHoverBg;
        }
    }

    .p-galleria-item-nav {
        width: $galleriaItemNavigatorWidth;
        height: $galleriaItemNavigatorHeight;
        margin: $galleriaItemNavigatorMargin;

        color: $galleriaItemNavigatorColor;

        background: $galleriaItemNavigatorBg;
        border-radius: $galleriaItemNavigatorBorderRadius;

        transition: $actionIconTransition;

        .p-galleria-item-prev-icon,
        .p-galleria-item-next-icon {
            font-size: $galleriaItemNavigatorFontSize;
        }

        .p-icon-wrapper {
            .p-icon {
                width: $galleriaCloseIconFontSize;
                height: $galleriaCloseIconFontSize;
            }
        }

        &:not(.p-disabled) {
            &:hover {
                color: $galleriaItemNavigatorHoverColor;
                background: $galleriaItemNavigatorHoverBg;
            }
        }
    }

    .p-galleria-caption {
        padding: $galleriaCaptionPadding;
        color: $galleriaCaptionTextColor;
        background: $galleriaCaptionBg;
    }

    .p-galleria-indicators {
        padding: $galleriaIndicatorsPadding;

        .p-galleria-indicator {
            button {
                width: $galleriaIndicatorWidth;
                height: $galleriaIndicatorHeight;

                background-color: $galleriaIndicatorBg;
                border-radius: $galleriaIndicatorBorderRadius;

                transition: $actionIconTransition;

                &:hover {
                    background: $galleriaIndicatorHoverBg;
                }
            }

            &.p-highlight {
                button {
                    color: $highlightTextColor;
                    background: $highlightBg;
                }
            }
        }
    }

    &.p-galleria-indicators-bottom,
    &.p-galleria-indicators-top {
        .p-galleria-indicator {
            margin-right: $inlineSpacing;
        }
    }

    &.p-galleria-indicators-left,
    &.p-galleria-indicators-right {
        .p-galleria-indicator {
            margin-bottom: $inlineSpacing;
        }
    }

    &.p-galleria-indicator-onitem {
        .p-galleria-indicators {
            background: $galleriaIndicatorsBgOnItem;

            .p-galleria-indicator {
                button {
                    background: $galleriaIndicatorBgOnItem;

                    &:hover {
                        background: $galleriaIndicatorHoverBgOnItem;
                    }
                }

                &.p-highlight {
                    button {
                        color: $highlightTextColor;
                        background: $highlightBg;
                    }
                }
            }
        }
    }

    .p-galleria-thumbnail-container {
        padding: $galleriaThumbnailContainerPadding;
        background: $galleriaThumbnailContainerBg;

        .p-galleria-thumbnail-prev,
        .p-galleria-thumbnail-next {
            width: $galleriaThumbnailNavigatorWidth;
            height: $galleriaThumbnailNavigatorHeight;
            margin: $inlineSpacing;

            color: $galleriaThumbnailNavigatorColor;

            background-color: $galleriaThumbnailNavigatorBg;
            border-radius: $galleriaThumbnailNavigatorBorderRadius;

            transition: $actionIconTransition;

            &:hover {
                color: $galleriaThumbnailNavigatorHoverColor;
                background: $galleriaThumbnailNavigatorHoverBg;
            }
        }
    }
}

.p-galleria-mask {
    --maskbg: #{$galleriaMaskBg};
}
