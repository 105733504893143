.p-panel {
    .p-panel-header {
        padding: $panelHeaderPadding;

        color: $panelHeaderTextColor;

        background: $panelHeaderBg;
        border: $panelHeaderBorder;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;

        .p-panel-title {
            font-weight: $panelHeaderFontWeight;
        }

        .p-panel-header-icon {
            @include action-icon;
        }
    }

    &.p-panel-toggleable {
        .p-panel-header {
            padding: $panelToggleableHeaderPadding;
        }
    }

    .p-panel-content {
        padding: $panelContentPadding;

        color: $panelContentTextColor;

        background: $panelContentBg;
        border: $panelContentBorder;
        border-top: 0 none;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-panel-footer {
        padding: $panelFooterPadding;

        color: $panelFooterTextColor;

        background: $panelFooterBg;
        border: $panelFooterBorder;
        border-top: 0 none;
    }

    .p-panel-icons-end {
        order: 2;
        margin-left: auto;
    }

    .p-panel-icons-start {
        order: 0;
        margin-right: $inlineSpacing;
    }

    .p-panel-icons-center {
        order: 2;
        width:100%;
        text-align:center;
    }
}

.p-button.p-panel-button {
  color: var(--text-color);
}
