.p-tabmenu {
    .p-tabmenu-nav {
        background: $tabviewNavBg;
        border: $tabviewNavBorder;
        border-width: $tabviewNavBorderWidth;
        

        .p-menuitem-badge {
            @include menuitem-badge;
        }

        .p-tabmenuitem {
            margin-right: $tabviewHeaderSpacing;

            .p-menuitem-link {
                margin: $tabviewHeaderMargin;
                padding: $tabviewHeaderPadding;

                font-weight: $tabviewHeaderFontWeight;
                color: $tabviewHeaderTextColor;

                background: $tabviewHeaderBg;
                border: $tabviewHeaderBorder;
                border-width: $tabviewHeaderBorderWidth;
                border-color: $tabviewHeaderBorderColor;
                border-top-left-radius: $borderRadius;
                border-top-right-radius: $borderRadius;

                transition: $listItemTransition;

                .p-menuitem-icon {
                    margin-right: $inlineSpacing;
                }

                &:not(.p-disabled):focus {
                    @include focused-inset;
                }
            }

            &:not(.p-highlight, .p-disabled):hover {
                .p-menuitem-link {
                    color: $tabviewHeaderTextHoverColor;
                    background: $tabviewHeaderHoverBg;
                    border-color: $tabviewHeaderHoverBorderColor;
                }
            }

            &.p-highlight {
                .p-menuitem-link {
                    color: $tabviewHeaderTextActiveColor;
                    background: $tabviewHeaderActiveBg;
                    border-color: $tabviewHeaderActiveBorderColor;
                }
            }
        }
    }

    .p-tabmenu-left-icon {
        margin-right: $inlineSpacing;
    }

    .p-tabmenu-right-icon {
        margin-left: $inlineSpacing;
    }

    .p-tabmenu-nav-btn.p-link {
        width: $buttonIconOnlyWidth;

        color: $tabviewHeaderTextActiveColor;

        background: $tabviewHeaderActiveBg;
        border-radius: 0;
        box-shadow: $raisedButtonShadow;

        &:focus {
            @include focused-inset;
        }
    }
}
