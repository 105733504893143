.p-breadcrumb {
    padding: $breadcrumbPadding;
    background: $breadcrumbBg;
    border: $breadcrumbBorder;
    border-radius: $borderRadius;

    .p-breadcrumb-list {
        li {
            .p-menuitem-link {
                border-radius: $borderRadius;
                transition: $listItemTransition;

                &:focus {
                    @include focused;
                }

                .p-menuitem-text {
                    color: $breadcrumbItemTextColor;
                }

                .p-menuitem-icon {
                    color: $breadcrumbItemIconColor;
                }
            }

            &.p-menuitem-separator {
                margin: 0 $inlineSpacing 0 $inlineSpacing;
                color: $breadcrumbSeparatorColor;
            }

            &:last-child {
                .p-menuitem-text {
                    color: $breadcrumbLastItemTextColor;
                }

                .p-menuitem-icon {
                    color: $breadcrumbLastItemIconColor;
                }
            }
        }
    }
}
