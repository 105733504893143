.p-selectbutton {
    .p-button {
        color: $toggleButtonTextColor;
        background: $toggleButtonBg;
        border: $toggleButtonBorder;
        transition: $formElementTransition;

        .p-button-icon-left,
        .p-button-icon-right {
            color: $toggleButtonIconColor;
        }

        &:not(.p-disabled, .p-highlight):hover {
            color: $toggleButtonTextHoverColor;
            background: $toggleButtonHoverBg;
            border-color: $toggleButtonHoverBorderColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconHoverColor;
            }
        }

        &.p-highlight {
            color: $toggleButtonTextActiveColor;
            background: $toggleButtonActiveBg;
            border-color: $toggleButtonActiveBorderColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconActiveColor;
            }

            &:hover {
                color: $toggleButtonTextActiveHoverColor;
                background: $toggleButtonActiveHoverBg;
                border-color: $toggleButtonActiveHoverBorderColor;

                .p-button-icon-left,
                .p-button-icon-right {
                    color: $toggleButtonIconActiveHoverColor;
                }
            }
        }
    }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    @include invalid-input;
}