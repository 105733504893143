.p-badge {
    min-width: $badgeMinWidth;
    height: $badgeHeight;

    font-size: $badgeFontSize;
    font-weight: $badgeFontWeight;
    line-height: $badgeHeight;
    color: $badgeTextColor;

    background: $badgeBg;

    &.p-badge-secondary {
        color: $secondaryButtonTextColor;
        background-color: $secondaryButtonBg;
    }

    &.p-badge-success {
        color: $successButtonTextColor;
        background-color: $successButtonBg;
    }

    &.p-badge-info {
        color: $infoButtonTextColor;
        background-color: $infoButtonBg;
    }

    &.p-badge-warning {
        color: $warningButtonTextColor;
        background-color: $warningButtonBg;
    }

    &.p-badge-danger {
        color: $dangerButtonTextColor;
        background-color: $dangerButtonBg;
    }

    &.p-badge-lg {
        min-width: 1.5 * $badgeMinWidth;
        height: 1.5 * $badgeHeight;
        font-size: 1.5 * $badgeFontSize;
        line-height: 1.5 * $badgeHeight;
    }

    &.p-badge-xl {
        min-width: 2 * $badgeMinWidth;
        height: 2 * $badgeHeight;
        font-size: 2 * $badgeFontSize;
        line-height: 2 * $badgeHeight;
    }
}