.p-paginator {
  justify-content: flex-end !important;
  padding: $paginatorPadding;

  color: $paginatorTextColor;

  background: $paginatorBg;
  border: $paginatorBorder;
  border-width: $paginatorBorderWidth;
  border-radius: $borderRadius;

  .p-paginator-first,
  .p-paginator-prev,
  .p-paginator-next,
  .p-paginator-last {
    min-width: $paginatorElementWidth;
    height: $paginatorElementHeight;
    margin: $paginatorElementMargin;

    color: $paginatorElementIconColor;

    background-color: $paginatorElementBg;
    border: $paginatorElementBorder;
    border-radius: $paginatorElementBorderRadius;

    transition: $listItemTransition;

    &.p-disabled {
      color: rgb(0 0 0 / 25%);
    }

    &:not(.p-disabled, .p-highlight):hover {
      color: $paginatorElementIconHoverColor;
      background: $paginatorElementHoverBg;
      border-color: $paginatorElementHoverBorderColor;
    }
  }

  .p-paginator-first {
    border-top-left-radius: $paginatorElementBorderRadius;
    border-bottom-left-radius: $paginatorElementBorderRadius;
  }

  .p-paginator-next {
    margin-right: 1.2rem;
  }

  .p-paginator-last {
    border-top-right-radius: $paginatorElementBorderRadius;
    border-bottom-right-radius: $paginatorElementBorderRadius;
  }

  .p-dropdown {
    height: $paginatorElementHeight;
    margin-top: 0;
    margin-right: $inlineSpacing;

    .p-dropdown-label {
      padding-right: 0;
    }
  }

  .p-paginator-page-input {
    margin-right: $inlineSpacing;
    margin-left: $inlineSpacing;

    .p-inputtext {
      max-width: $paginatorElementWidth;
    }
  }

  .p-paginator-current {
    min-width: $paginatorElementWidth;
    height: $paginatorElementHeight;
    margin: 0 0.8rem 0 0;
    padding: 0;

    color: $paginatorElementIconColor;

    background-color: $paginatorElementBg;
    border: $paginatorElementBorder;
  }

  .p-paginator-pages {
    display: flex;
    gap: 0.8rem;

    .p-paginator-page {
      min-width: $paginatorElementWidth;
      height: $paginatorElementHeight;
      margin: $paginatorElementMargin;

      color: $paginatorElementIconColor;

      background-color: $paginatorElementBg;
      border: $paginatorElementBorder;
      border-radius: $paginatorElementBorderRadius;

      transition: $listItemTransition;

      &.p-highlight {
        font-weight: $paginatorHighlightFontWeight;
        color: $paginatorHighlightTextColor;
        background: $paginatorHighlightBg;
        border-color: $paginatorHighlightBorderColor;
      }

      &:not(.p-highlight):hover {
        color: $paginatorElementIconHoverColor;
        background: $paginatorElementHoverBg;
        border-color: $paginatorElementHoverBorderColor;
      }
    }
  }

  .p-paginator-icon {
    display: flex;
  }
}
