.p-card {
    color: $panelContentTextColor;
    background: $cardContentBg;
    border-radius: $borderRadius;
    box-shadow: $cardShadow;

    .p-card-body {
        padding: $cardBodyPadding;
    }

    .p-card-title {
        margin-bottom: $inlineSpacing;

        font-size: $cardTitleFontSize;
        font-weight: $cardTitleFontWeight;
        line-height: $cardTitleLineHeight;
        color: $cardTitleColor;
    }

    .p-card-subtitle {
        margin-bottom: $inlineSpacing;
        font-weight: $cardSubTitleFontWeight;
        color: $cardSubTitleColor;
    }

    .p-card-content {
        padding: $cardContentPadding;
    }

    .p-card-footer {
        padding: $cardFooterPadding;
    }
}
