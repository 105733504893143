/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 0.5rem 1.6rem;

/// Padding of a small button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem" (custom)
/// @group button
$buttonPaddingSm: 0.3rem 0.8rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.357rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: 0.5rem 0;

/// Background of a button
/// @group button
$buttonBg: $primaryColor;

/// Text color of a button
/// @group button
$buttonTextColor: $primaryTextColor;

/// Border of a button
/// @group button
$buttonBorder: thin solid $primaryColor;

/// Background of a button in hover state
/// @group button
$buttonHoverBg: $primaryDarkColor;

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: $primaryTextColor;

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: $primaryDarkColor;

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: $primaryDarkerColor;

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: $primaryTextColor;

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: $primaryDarkerColor;

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
  0 2px 2px 0 rgb(0 0 0 / 14%),
  0 1px 5px 0 rgb(0 0 0 / 12%);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: 0.04;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity: 0.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder: thin solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: var(--text-color);

/// Border color of a plain text button (custom)
/// @group button
$plainButtonBorderColor: rgb(0 0 0 / 15%);

/// Background of a plain text button (custom)
/// @group button
$plainButtonBg: rgb(0 0 0 / 2%);

/// Text color of a plain text button in hover state (custom)
/// @group button
$plainButtonTextHoverColor: var(--text-color);

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: rgb(0 0 0 / 10%);

/// Text color of a plain text button in active state (custom)
/// @group button
$plainButtonTextActiveColor: var(--text-color);

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor: rgb(0 0 0 / 10%);

/// Background of a secondary button
/// @group button
$secondaryButtonBg: rgb(0 0 0 / 88%);

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: white;

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: thin solid rgb(0 0 0 / 15%);

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: rgb(0 0 0 / 75%);

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: white;

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: rgb(0 0 0 / 15%);

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: rgb(0 0 0 / 75%);

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: white;

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: rgb(0 0 0 / 15%);

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: 0 0 0 0.2rem rgb(0 0 0 / 15%);

/// Background of an info button
/// @group button
$infoButtonBg: #03a9f4;

/// Text color of an info button
/// @group button
$infoButtonTextColor: white;

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid #03a9f4;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: #039be5;

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: white;

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: #039be5;

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: #0288d1;

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: white;

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: #0288d1;

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: 0 0 0 0.2rem lighten($infoButtonBg, 35%);

/// Background of a success button
/// @group button
$successButtonBg: #f6ffed;

/// Text color of a success button
/// @group button
$successButtonTextColor: #52c41a;

/// Border of a success button
/// @group button
$successButtonBorder: thin solid #b7eb8f;

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: #43a047;

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: white;

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: #43a047;

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: #388e3c;

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: white;

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: #388e3c;

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: 0 0 0 0.2rem lighten($successButtonBg, 35%);

/// Background of a warning button
/// @group button
$warningButtonBg: #fffbe6;

/// Text color of a warning button
/// @group button
$warningButtonTextColor: #faad14;

/// Border of a warning button
/// @group button
$warningButtonBorder: thin solid #ffc107;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: #ffe58f;

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: $textColor;

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: #ffb300;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: #ffa000;

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: $textColor;

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: #ffa000;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: 0 0 0 0.2rem lighten($warningButtonBg, 35%);

/// Background of a help button
/// @group button
$helpButtonBg: #9c27b0;

/// Text color of a help button
/// @group button
$helpButtonTextColor: white;

/// Border of a help button
/// @group button
$helpButtonBorder: 1px solid #9c27b0;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg: #8e24aa;

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor: white;

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor: #8e24aa;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg: #7b1fa2;

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor: white;

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor: #7b1fa2;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow: 0 0 0 0.2rem #ce93d8;

/// Background of a danger button
/// @group button
$dangerButtonBg: transparent;

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: $error;

/// Border of a danger button
/// @group button
$dangerButtonBorder: thin solid $error;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: #e53935;

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: white;

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: #e53935;

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: #d32f2f;

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: white;

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: #d32f2f;

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: 0 0 0 0.2rem lighten($dangerButtonBg, 35%);

/// Text color of a link button
/// @group button
$linkButtonColor: var(--text-color);

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor: $primaryDarkerColor;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration: underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.2rem $focusOutlineColor;

/// Background of a toggle button
/// @group button
$toggleButtonBg: white;

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid #ced4da;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: rgb(0 0 0 / 4%);

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: #ced4da;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $primaryColor;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $primaryColor;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $primaryTextColor;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $primaryTextColor;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $primaryDarkColor;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $primaryTextColor;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $primaryTextColor;

/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial
/// @group button
$speedDialActionTextColor: white;

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: white;
