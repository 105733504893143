.icon {
  display: inline-flex;
  width: 1.6rem;
  height: 1.6rem;
  

  background-color: currentcolor;

  mask-position: center;
  mask-size: contain;
  mask-repeat: no-repeat;

  &--lg {
    width: 2.4rem;
    height: 2.4rem;
  }
}
  
.p-menu .icon {
  flex-shrink: 0;
}

@each $name in $icons {
  .icon--#{$name} {
    mask-image: url('../../assets/img/icons/#{$name}.svg');
  }
}

@each $name in $primary-icons {
  .icon--#{$name}-primary {
    background-color: transparent;
    background-image: url('../../assets/img/icons/#{$name}-primary.svg');
    mask-image: none;
  }
}
