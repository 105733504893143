.p-dropdown {
    width: $inputWidth;
    margin-top: $inputMarginTop;

    background: $inputBg;
    border: $inputBorder;
    border-radius: $borderRadius;

    transition: $formElementTransition;

    &:not(.p-disabled):hover {
        border-color: $inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include focused-input;
    }

    &.p-dropdown-clearable {
        .p-dropdown-label {
            padding-right: nth($inputPadding, 2) + $primeIconFontSize;
        }
    }

    .p-dropdown-label {
        background: transparent;
        border: 0 none;

        &.p-placeholder {
            color: $inputListPlaceholderTextColor;
        }

        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }
    }

    .p-dropdown-trigger {
        width: $inputGroupAddOnMinWidth;

        color: $inputIconColor;

        background: transparent;
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
    }

    .p-dropdown-clear-icon {
        right: $inputGroupAddOnMinWidth;
        color: $inputIconColor;
    }
}

.p-dropdown-panel {
    color: $inputListTextColor;

    background: $inputOverlayBg;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-dropdown-header {
        margin: $inputListHeaderMargin;
        padding: $inputListHeaderPadding;

        color: $inputListHeaderTextColor;

        background: $inputOverlayHeaderBg;
        border-bottom: $inputListHeaderBorder;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;

        .p-dropdown-filter {
            margin-right: -1 * (nth($inputPadding, 2) + $primeIconFontSize);
            padding-right: nth($inputPadding, 2) + $primeIconFontSize;
        }

        .p-dropdown-filter-icon {
            right: nth($inputPadding, 2);
            color: $inputIconColor;
        }
    }

    .p-dropdown-items {
        padding: $inputListPadding;

        .p-dropdown-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;

            color: $inputListItemTextColor;

            background: $inputListItemBg;
            border: $inputListItemBorder;
            border-radius: $inputListItemBorderRadius;

            transition: $listItemTransition;

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;
            }

            &:not(.p-highlight, .p-disabled):hover {
                color: $inputListItemTextHoverColor;
                background: $inputListItemHoverBg;
            }
        }

        .p-dropdown-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }

        .p-dropdown-item-group {
            margin: $submenuHeaderMargin;
            padding: $submenuHeaderPadding;

            font-weight: $submenuHeaderFontWeight;
            color: $submenuHeaderTextColor;

            background: $submenuHeaderBg;
        }
    }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    @include invalid-input;
}

p-dropdown.ng-invalid.err > .p-dropdown {
    @include invalid-input;
}

.p-input-filled {
    .p-dropdown {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;

            .p-inputtext {
                background-color: transparent;
            }
        }
    }
}

.p-dropdown {
    &.p-disabled {
        background-color: rgba(0, 0, 0, 0.04);
    }
}
