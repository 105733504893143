/* Customizations to the designer theme should be defined here */

.w-60rem {
  width: 60rem !important;
}

.w-1\.2rem {
  width: 1.2rem !important;
}

.h-1\.2rem {
  height: 1.2rem !important;
}

.p-24px {
  padding: 2.4rem !important;
}

.table-layout-fixed {
  table-layout: fixed;
}

.text-secondary {
  color: $textSecondaryColor;
}

.text-required {
  color: $salmon;
}

.text-light {
  color: rgb(0 0 0 / 45%);
}

.bg-secondary {
  color: $secondaryTextColor;
  background-color: $secondaryColor;
}

.bg-link-water {
  background-color: $link-water;
}

.border-silver {
  border-color: $silver;
}

.border-seashell {
  border-color: $seashell;
}

.visually-hidden {
  position: absolute;

  width: 0.1rem;
  height: 0.1rem;
  overflow: hidden;

  clip: rect(0, 0, 0, 0);
}
