@use 'sass:math';

.p-autocomplete {
    .p-autocomplete-loader {
        right: nth($inputPadding, 2);
    }

    &.p-autocomplete-dd {
        .p-autocomplete-loader {
            right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
        }
    }

    .p-autocomplete-multiple-container {
        gap: $inlineSpacing;
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);

        &:not(.p-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input;
        }

        .p-autocomplete-input-token {
            padding: math.div(nth($inputPadding, 1), 2) 0;

            input {
                margin: 0;
                padding: 0;

                font-family: $fontFamily;
                font-size: $fontSize;
                color: $textColor;
            }
        }

        .p-autocomplete-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            color: $highlightTextColor;
            background: $highlightBg;
            border-radius: $borderRadius;

            .p-autocomplete-token-icon {
                margin-left: $inlineSpacing;
            }
        }
    }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include invalid-input;
}

.p-autocomplete-panel {
    color: $inputListTextColor;

    background: $inputOverlayBg;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-autocomplete-items {
        padding: $inputListPadding;

        .p-autocomplete-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;

            color: $inputListItemTextColor;

            background: $inputListItemBg;
            border: $inputListItemBorder;
            border-radius: $inputListItemBorderRadius;

            transition: $listItemTransition;

            &:hover {
                color: $inputListItemTextHoverColor;
                background: $inputListItemHoverBg;
            }

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;
            }
        }

        .p-autocomplete-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }

        .p-autocomplete-item-group {
            margin: $submenuHeaderMargin;
            padding: $submenuHeaderPadding;

            font-weight: $submenuHeaderFontWeight;
            color: $submenuHeaderTextColor;

            background: $submenuHeaderBg;
        }
    }
}

p-autocomplete.p-autocomplete-clearable {
    .p-inputtext {
        padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
    }

    .p-autocomplete-clear-icon {
        right: nth($inputPadding, 2);
        color: $inputIconColor;
    }
}

p-autocomplete.p-autocomplete-clearable .p-autocomplete-dd {
    .p-autocomplete-clear-icon {
        right: $buttonIconOnlyWidth + nth($inputPadding, 2);
        color: $inputIconColor;
    }
}
