.p-inline-message {
    margin: $inlineMessageMargin;
    padding: $inlineMessagePadding;
    border-radius: $borderRadius;

    &.p-inline-message-info {
        color: $infoMessageTextColor;
        background: $infoMessageBg;
        border: $infoMessageBorder;
        border-width: $inlineMessageBorderWidth;

        .p-inline-message-icon {
            color: $infoMessageIconColor;
        }
    }

    &.p-inline-message-success {
        color: $successMessageTextColor;
        background: $successMessageBg;
        border: $successMessageBorder;
        border-width: $inlineMessageBorderWidth;

        .p-inline-message-icon {
            color: $successMessageIconColor;
        }
    }

    &.p-inline-message-warn {
        color: $warningMessageTextColor;
        background: $warningMessageBg;
        border: $warningMessageBorder;
        border-width: $inlineMessageBorderWidth;

        .p-inline-message-icon {
            color: $warningMessageIconColor;
        }
    }

    &.p-inline-message-error {
        color: $errorMessageTextColor;
        background: $errorMessageBg;
        border: $errorMessageBorder;
        border-width: $inlineMessageBorderWidth;

        .p-inline-message-icon {
            color: $errorMessageIconColor;
        }
    }

    .p-inline-message-icon {
        margin-right: $inlineSpacing;
        font-size: $inlineMessageIconFontSize;
    }

    .p-icon {
        width: $inlineMessageIconFontSize;
        height: $inlineMessageIconFontSize;
    }

    .p-inline-message-text {
        font-size: $inlineMessageTextFontSize;
    }

    &.p-inline-message-icon-only {
        .p-inline-message-icon {
            margin-right: 0;
        }
    }
}
