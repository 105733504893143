@use 'sass:math';

.p-overlaypanel {
  color: $panelContentTextColor;

  background: $overlayContentBg;
  border: $overlayContentBorder;
  border-radius: $borderRadius;
  box-shadow: $overlayContainerShadow;

  .p-overlaypanel-content {
    padding: $panelContentPadding;
  }

  .p-overlaypanel-close {
    // position: absolute;
    top: math.div(1 * $actionIconWidth, 2);
    right: math.div(-1 * $actionIconWidth, 2);

    width: $actionIconWidth;
    height: $actionIconHeight;

    color: $buttonTextColor;

    background: $buttonBg;
    border-radius: $actionIconBorderRadius;

    transition: $actionIconTransition;

    &:enabled:hover {
      color: $buttonTextHoverColor;
      background: $buttonHoverBg;
    }
  }

  &::after {
    border: solid transparent;
    border-color: rgba($overlayContentBg, 0);
    border-bottom-color: $overlayContentBg;
  }

  &::before {
    border: solid transparent;

    @if nth($overlayContentBorder, 2) == 'none' {
      border-color: rgba($overlayContentBg, 0);
      border-bottom-color: scale-color($overlayContentBg, $lightness: -5%);
    }

 @else {
      border-color: rgba(nth($overlayContentBorder, 3), 0);
      border-bottom-color: scale-color(nth($overlayContentBorder, 3), $lightness: -5%);
    }
  }

  &.p-overlaypanel-flipped {
    &::after {
      border-top-color: $overlayContentBg;
    }

    &::before {
      @if nth($overlayContentBorder, 2) == 'none' {
        border-top-color: $overlayContentBg;
      }

 @else {
        border-top-color: nth($overlayContentBorder, 3);
      }
    }
  }
}

.p-overlaypanel--filter {
  @extend .p-column-filter-overlay-menu;

  overflow: hidden;
  background-color: white;
  outline: $inputListBorder;
  box-shadow: $inputOverlayShadow;

  &::before,
  &::after {
    display: none;
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}
