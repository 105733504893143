.p-button {
  padding: $buttonPadding;

  font-size: $fontSize;
  color: $buttonTextColor;

  background: $buttonBg;
  border: $buttonBorder;
  border-radius: $borderRadius;

  transition: $formElementTransition;

  &:enabled:hover {
    color: $buttonTextHoverColor;
    background: $buttonHoverBg;
    border-color: $buttonHoverBorderColor;
  }

  &:enabled:active {
    color: $buttonTextActiveColor;
    background: $buttonActiveBg;
    border-color: $buttonActiveBorderColor;
  }

  &:disabled,
  &.p-disabled {
    color: rgb(0 0 0 / 25%) !important;

    background-color: rgb(0 0 0 / 4%) !important;
    border: thin solid rgb(0 0 0 / 15%) !important;
    opacity: 1 !important;

    pointer-events: none !important;
  }

  &.p-button-outlined {
    color: $buttonBg;
    background-color: transparent;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $buttonBg;
      background: rgba($buttonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $buttonBg;
      background: rgba($buttonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }

    &.p-button-secondary {
      border-color: rgb(0 0 0 / 15%) !important;
    }

    &.p-button-plain {
      color: $plainButtonTextColor;
      background: $plainButtonBg;
      border-color: $plainButtonBorderColor;

      &:enabled:hover {
        color: $plainButtonTextHoverColor;
        background: $plainButtonHoverBgColor;
      }

      &:enabled:active {
        color: $plainButtonTextActiveColor;
        background: $plainButtonActiveBgColor;
      }
    }
  }

  &.p-button-text {
    color: $buttonBg;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $buttonBg;
      background: rgba($buttonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $buttonBg;
      background: rgba($buttonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }

    &.p-button-plain {
      color: $plainButtonTextColor;

      &:enabled:hover {
        color: $plainButtonTextHoverColor;
        background: $plainButtonHoverBgColor;
      }

      &:enabled:active {
        color: $plainButtonTextActiveColor;
        background: $plainButtonActiveBgColor;
      }
    }
  }

  &:focus {
    @include focused;
  }

  .p-button-label {
    transition-duration: $transitionDuration;
  }

  .p-button-icon-left {
    margin-right: $inlineSpacing;
  }

  .p-button-icon-right {
    margin-left: $inlineSpacing;
  }

  .p-button-icon-bottom {
    margin-top: $inlineSpacing;
  }

  .p-button-icon-top {
    margin-bottom: $inlineSpacing;
  }

  .p-badge {
    min-width: $fontSize;
    height: $fontSize;
    margin-left: $inlineSpacing;

    line-height: $fontSize;
    color: $buttonBg;

    background-color: $buttonTextColor;
  }

  &.p-button-raised {
    box-shadow: $raisedButtonShadow;
  }

  &.p-button-rounded {
    border-radius: $roundedButtonBorderRadius;
  }

  &.p-button-icon-only {
    width: $buttonIconOnlyWidth;
    padding: $buttonIconOnlyPadding;

    .p-button-icon-left,
    .p-button-icon-right {
      margin: 0;
    }

    &.p-button-rounded {
      height: $buttonIconOnlyWidth;
      border-radius: 50%;
    }
  }

  &.p-button-sm {
    padding: $buttonPaddingSm;

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleSM);
    }
  }

  &.p-button-lg {
    @include scaledFontSize($fontSize, $scaleLG);
    @include scaledPadding($buttonPadding, $scaleLG);

    .p-button-icon {
      @include scaledFontSize($primeIconFontSize, $scaleLG);
    }
  }

  &.p-button-loading-label-only {
    .p-button-label {
      margin-left: $inlineSpacing;
    }

    .p-button-loading-icon {
      margin-right: 0;
    }
  }
}

.p-fluid {
  .p-button {
    width: 100%;
  }

  .p-button-icon-only {
    width: $buttonIconOnlyWidth;
  }

  .p-buttonset {
    display: flex;

    .p-button {
      flex: 1;
    }
  }
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: $secondaryButtonTextColor;
  background: $secondaryButtonBg;
  border: $secondaryButtonBorder;

  &:enabled:hover {
    color: $secondaryButtonTextHoverColor;
    background: $secondaryButtonHoverBg;
    border-color: $secondaryButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $secondaryButtonFocusShadow;
  }

  &:enabled:active {
    color: $secondaryButtonTextActiveColor;
    background: $secondaryButtonActiveBg;
    border-color: $secondaryButtonActiveBorderColor;
  }

  &.p-button-outlined {
    color: $secondaryButtonBg;
    background-color: white;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $secondaryButtonBg;
      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $secondaryButtonBg;
      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    color: $secondaryButtonBg;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $secondaryButtonBg;
      background: rgba($secondaryButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $secondaryButtonBg;
      background: rgba($secondaryButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }
  }
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: $infoButtonTextColor;
  background: $infoButtonBg;
  border: $infoButtonBorder;

  &:enabled:hover {
    color: $infoButtonTextHoverColor;
    background: $infoButtonHoverBg;
    border-color: $infoButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $infoButtonFocusShadow;
  }

  &:enabled:active {
    color: $infoButtonTextActiveColor;
    background: $infoButtonActiveBg;
    border-color: $infoButtonActiveBorderColor;
  }

  &.p-button-outlined {
    color: $infoButtonBg;
    background-color: transparent;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $infoButtonBg;
      background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $infoButtonBg;
      background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    color: $infoButtonBg;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $infoButtonBg;
      background: rgba($infoButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $infoButtonBg;
      background: rgba($infoButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }
  }
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: $successButtonTextColor;
  background: $successButtonBg;
  border: $successButtonBorder;

  &:enabled:hover {
    color: $successButtonTextHoverColor;
    background: $successButtonHoverBg;
    border-color: $successButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $successButtonFocusShadow;
  }

  &:enabled:active {
    color: $successButtonTextActiveColor;
    background: $successButtonActiveBg;
    border-color: $successButtonActiveBorderColor;
  }

  &.p-button-outlined {
    color: $successButtonBg;
    background-color: transparent;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $successButtonBg;
      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $successButtonBg;
      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    color: $successButtonBg;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $successButtonBg;
      background: rgba($successButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $successButtonBg;
      background: rgba($successButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }
  }
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: $warningButtonTextColor;
  background: $warningButtonBg;
  border: $warningButtonBorder;

  &:enabled:hover {
    color: $warningButtonTextHoverColor;
    background: $warningButtonHoverBg;
    border-color: $warningButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $warningButtonFocusShadow;
  }

  &:enabled:active {
    color: $warningButtonTextActiveColor;
    background: $warningButtonActiveBg;
    border-color: $warningButtonActiveBorderColor;
  }

  &.p-button-outlined {
    color: $warningButtonBg;
    background-color: transparent;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $warningButtonBg;
      background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $warningButtonBg;
      background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    color: $warningButtonBg;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $warningButtonBg;
      background: rgba($warningButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $warningButtonBg;
      background: rgba($warningButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }
  }
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: $helpButtonTextColor;
  background: $helpButtonBg;
  border: $helpButtonBorder;

  &:enabled:hover {
    color: $helpButtonTextHoverColor;
    background: $helpButtonHoverBg;
    border-color: $helpButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $helpButtonFocusShadow;
  }

  &:enabled:active {
    color: $helpButtonTextActiveColor;
    background: $helpButtonActiveBg;
    border-color: $helpButtonActiveBorderColor;
  }

  &.p-button-outlined {
    color: $helpButtonBg;
    background-color: transparent;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $helpButtonBg;
      background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $helpButtonBg;
      background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    color: $helpButtonBg;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $helpButtonBg;
      background: rgba($helpButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $helpButtonBg;
      background: rgba($helpButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }
  }
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: $dangerButtonTextColor;
  background: $dangerButtonBg;
  border: $dangerButtonBorder;

  &:enabled:hover {
    color: $dangerButtonTextHoverColor;
    background: $dangerButtonHoverBg;
    border-color: $dangerButtonHoverBorderColor;
  }

  &:enabled:focus {
    box-shadow: $dangerButtonFocusShadow;
  }

  &:enabled:active {
    color: $dangerButtonTextActiveColor;
    background: $dangerButtonActiveBg;
    border-color: $dangerButtonActiveBorderColor;
  }

  &.p-button-outlined {
    color: $dangerButtonBg;
    background-color: transparent;
    border: $outlinedButtonBorder;

    &:enabled:hover {
      color: $dangerButtonBg;
      background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
      border: $outlinedButtonBorder;
    }

    &:enabled:active {
      color: $dangerButtonBg;
      background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
      border: $outlinedButtonBorder;
    }
  }

  &.p-button-text {
    color: $dangerButtonTextColor;
    background-color: transparent;
    border-color: transparent;

    &:enabled:hover {
      color: $dangerButtonTextColor;
      background: rgba($dangerButtonBg, $textButtonHoverBgOpacity);
      border-color: transparent;
    }

    &:enabled:active {
      color: $dangerButtonTextColor;
      background: rgba($dangerButtonBg, $textButtonActiveBgOpacity);
      border-color: transparent;
    }
  }
}

.p-button.p-button-link {
  padding: 0;
  color: $linkButtonColor;
  background: transparent;
  border: transparent;

  &:enabled:hover {
    color: $linkButtonHoverColor;
    background: transparent;
    border-color: transparent;

    .p-button-label {
      text-decoration: $linkButtonTextHoverDecoration;
    }
  }

  &:enabled:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: $linkButtonFocusShadow;
  }

  &:enabled:active {
    color: $linkButtonColor;
    background: transparent;
    border-color: transparent;
  }
}

.p-ghost-button {
  @extend .p-button-text;
  @extend .p-button-plain;

  border-color: transparent !important;
}
