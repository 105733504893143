.p-form-check {
  display: $checkboxDisplay;
  align-items: $checkboxAlignItems;
}

.p-checkbox {
  width: $checkboxWidth;
  height: $checkboxHeight;
  margin-top: $checkboxMarginTop;

  .p-checkbox-box {
    width: $checkboxWidth;
    height: $checkboxHeight;

    color: $textColor;

    background: $inputBg;
    border: $checkboxBorder;
    border-radius: 0.4rem;

    transition: $formElementTransition;

    .p-checkbox-icon {
      font-size: $checkboxIconFontSize;
      color: $checkboxIconActiveColor;
      transition-duration: $transitionDuration;
    }

    .p-icon {
      width: $checkboxIconFontSize;
      height: $checkboxIconFontSize;
    }

    &.p-highlight {
      background: $checkboxActiveBg;
      border-color: $checkboxActiveBorderColor;
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &:hover {
        border-color: $inputHoverBorderColor;
      }

      &.p-focus {
        @include focused-input;
      }

      &.p-highlight:hover {
        color: $checkboxIconActiveHoverColor;
        background: $checkboxActiveHoverBg;
        border-color: $checkboxActiveHoverBorderColor;
      }
    }
  }
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  @include invalid-input;
}

.p-input-filled {
  .p-checkbox {
    .p-checkbox-box {
      background-color: $inputFilledBg;

      &.p-highlight {
        background: $checkboxActiveBg;
      }
    }

    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          background-color: $inputFilledHoverBg;
        }

        &.p-highlight:hover {
          background: $checkboxActiveHoverBg;
        }
      }
    }
  }
}

.p-checkbox-label {
  margin-left: $inlineSpacing;
}

@if $highlightBg == $checkboxActiveBg {
  .p-highlight {
    .p-checkbox {
      .p-checkbox-box {
        border-color: $checkboxIconActiveColor;
      }
    }
  }
}

.field-checkbox {
  margin-bottom: 1.3rem;

  &:last-child {
    margin-bottom: 0;
  }

  > label {
    margin-left: 0.8rem;
    line-height: inherit;
  }
}

.p-table-checkbox,
.p-listbox {
  .p-icon-wrapper {
    svg {
      display: none;
    }
  }

  .p-highlight .p-icon-wrapper {
    @extend .icon;
    @extend .icon--checkmark;

    color: white;
  }
}
