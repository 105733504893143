.fc {
    /* FullCalendar 4 */
    &.fc-unthemed {
        .fc-view-container {
            th {
                color: $panelHeaderTextColor;
                background: $panelHeaderBg;
                border: $panelHeaderBorder;
            }

            td {
                &.fc-widget-content {
                    color: $panelContentTextColor;
                    background: $panelContentBg;
                    border: $panelContentBorder;
                }

                &.fc-head-container {
                    border: $panelHeaderBorder;
                }
            }

            .fc-row {
                border-right: $panelHeaderBorder;
            }

            .fc-event {
                color: $fullCalendarEventTextColor;
                background: $fullCalendarEventBg;
                border: $fullCalendarEventBorder;
            }

            .fc-divider {
                background: $panelHeaderBg;
                border: $panelHeaderBorder;
            }
        }

        .fc-toolbar {
            .fc-button {
                display: flex;
                align-items: center;

                font-size: $fontSize;
                color: $buttonTextColor;

                background: $buttonBg;
                border: $buttonBorder;
                border-radius: $borderRadius;

                transition: $formElementTransition;

                &:enabled:hover {
                    color: $buttonTextHoverColor;
                    background: $buttonHoverBg;
                    border-color: $buttonHoverBorderColor;
                }

                &:enabled:active {
                    color: $buttonTextActiveColor;
                    background: $buttonActiveBg;
                    border-color: $buttonActiveBorderColor;

                    &:focus {
                        @include focused;
                    }
                }

                .fc-icon-chevron-left {
                    @include icon_override("\e900");

                    font-family: PrimeIcons !important;
                    font-size: $primeIconFontSize;
                    text-indent: 0;
                }

                .fc-icon-chevron-right {
                    @include icon_override("\e901");

                    font-family: PrimeIcons !important;
                    font-size: $primeIconFontSize;
                    text-indent: 0;
                }

                &:focus {
                    @include focused;
                }

                &.fc-dayGridMonth-button,
                &.fc-timeGridWeek-button,
                &.fc-timeGridDay-button {
                    color: $toggleButtonTextColor;
                    background: $toggleButtonBg;
                    border: $toggleButtonBorder;
                    transition: $formElementTransition;

                    &:hover {
                        color: $toggleButtonTextHoverColor;
                        background: $toggleButtonHoverBg;
                        border-color: $toggleButtonHoverBorderColor;
                    }

                    &.fc-button-active {
                        color: $toggleButtonTextActiveColor;
                        background: $toggleButtonActiveBg;
                        border-color: $toggleButtonActiveBorderColor;

                        &:hover {
                            color: $toggleButtonTextActiveHoverColor;
                            background: $toggleButtonActiveHoverBg;
                            border-color: $toggleButtonActiveHoverBorderColor;
                        }
                    }

                    &:focus {
                        @include focused;

                        z-index: 1;
                    }
                }
            }

            .fc-button-group {
                .fc-button {
                    border-radius: 0;

                    &:first-child {
                        border-top-left-radius: $borderRadius;
                        border-bottom-left-radius: $borderRadius;
                    }

                    &:last-child {
                        border-top-right-radius: $borderRadius;
                        border-bottom-right-radius: $borderRadius;
                    }
                }
            }
        }
    }

    /* FullCalendar 5 */
    &.fc-theme-standard {
        .fc-view-harness {
            .fc-scrollgrid {
                border-color: $panelContentBorderColor;
            }

            th {
                color: $panelHeaderTextColor;
                background: $panelHeaderBg;
                border-color: $panelHeaderBorderColor;
            }

            td {
                color: $panelContentTextColor;
                border-color: $panelContentBorderColor;
            }

            .fc-view {
                background: $panelContentBg;
            }

            .fc-popover {
                background: none;
                border: 0 none;

                .fc-popover-header {
                    padding: $panelHeaderPadding;
                    color: $panelHeaderTextColor;
                    background: $panelHeaderBg;
                    border: $panelHeaderBorder;

                    .fc-popover-close {
                        @include icon_override("\e90b");
                        @include action-icon(false);

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        font-family: PrimeIcons !important;
                        font-size: $primeIconFontSize;

                        opacity: 1;
                    }
                }

                .fc-popover-body {
                    padding: $panelContentPadding;

                    color: $panelContentTextColor;

                    background: $panelContentBg;
                    border: $panelContentBorder;
                    border-top: 0 none;
                }
            }

            .fc-event {
                &.fc-daygrid-block-event {
                    color: $fullCalendarEventTextColor;
                    background: $fullCalendarEventBg;
                    border-color: $fullCalendarEventBorderColor;

                    .fc-event-main {
                        color: $fullCalendarEventTextColor;
                    }
                }

                &.fc-daygrid-dot-event {
                    .fc-daygrid-event-dot {
                        background: $fullCalendarEventBg;
                        border-color: $fullCalendarEventBorderColor;
                    }

                    &:hover {
                        color: $inputListItemTextHoverColor;
                        background: $inputListItemHoverBg;
                    }
                }
            }

            .fc-cell-shaded {
                background: $panelHeaderBg;
            }
        }

        .fc-toolbar {
            .fc-button {
                font-size: $fontSize;
                color: $buttonTextColor;

                background: $buttonBg;
                border: $buttonBorder;
                border-radius: $borderRadius;

                transition: $formElementTransition;

                &:enabled:hover {
                    color: $buttonTextHoverColor;
                    background: $buttonHoverBg;
                    border-color: $buttonHoverBorderColor;
                }

                &:enabled:active {
                    color: $buttonTextActiveColor;
                    background: $buttonActiveBg;
                    border-color: $buttonActiveBorderColor;

                    &:focus {
                        @include focused;
                    }
                }

                &:disabled {
                    color: $buttonTextColor;
                    background: $buttonBg;
                    border: $buttonBorder;
                    opacity: $disabledOpacity;
                }

                .fc-icon-chevron-left {
                    @include icon_override("\e900");

                    font-family: PrimeIcons !important;
                    font-size: $primeIconFontSize;
                    text-indent: 0;
                }

                .fc-icon-chevron-right {
                    @include icon_override("\e901");

                    font-family: PrimeIcons !important;
                    font-size: $primeIconFontSize;
                    text-indent: 0;
                }

                &:focus {
                    @include focused;
                }

                &.fc-dayGridMonth-button,
                &.fc-timeGridWeek-button,
                &.fc-timeGridDay-button {
                    color: $toggleButtonTextColor;
                    background: $toggleButtonBg;
                    border: $toggleButtonBorder;
                    transition: $formElementTransition;

                    &:hover {
                        color: $toggleButtonTextHoverColor;
                        background: $toggleButtonHoverBg;
                        border-color: $toggleButtonHoverBorderColor;
                    }

                    &.fc-button-active {
                        color: $toggleButtonTextActiveColor;
                        background: $toggleButtonActiveBg;
                        border-color: $toggleButtonActiveBorderColor;

                        &:hover {
                            color: $toggleButtonTextActiveHoverColor;
                            background: $toggleButtonActiveHoverBg;
                            border-color: $toggleButtonActiveHoverBorderColor;
                        }
                    }

                    &:not(:disabled) {
                        &:focus {
                            @include focused;

                            z-index: 1;
                        }
                    }
                }
            }

            .fc-button-group {
                .fc-button {
                    border-radius: 0;

                    &:first-child {
                        border-top-left-radius: $borderRadius;
                        border-bottom-left-radius: $borderRadius;
                    }

                    &:last-child {
                        border-top-right-radius: $borderRadius;
                        border-bottom-right-radius: $borderRadius;
                    }
                }
            }
        }

        a {
            color: $textColor;
        }

        .fc-highlight {
            color: $highlightTextColor;
            background: $highlightBg;
        }
    }
}
