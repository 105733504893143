// Custom component
.description-block--full {
  .description-list {
    width: 100% !important;
  }
}

.description-list {
  margin: 0;
  padding: 2rem;
  background-color: white;
  border-radius: var(--border-radius);

  :first-child {
    .description-list__row {
      padding-top: 0;
    }
  }

  &__row {
    display: flex;
    gap: 2.8rem;
    padding: 1.6rem 0 0.5rem;
    border-bottom: thin solid rgb(0 0 0 / 10%);

    &-rejected {
      padding: 0;
      border-bottom: none !important;
    }
  }

  p {
    width: 60%;
    margin: 0;
    padding: 0 !important;

    &:first-child {
      width: 40%;
      font-weight: 600;
    }
  }

  .description-list--updated {
    color: $silver;
  }
}
