body,
.text-body {
  font-size: $fontSize;
  line-height: 1.57;
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    margin: 0;
    font-size: nth($h-font-sizes, $i);
    font-weight: 600;
    line-height: nth($h-line-heights, $i);
  }
}

ul,
ol {
  padding-left: 2rem;
}
