@use 'sass:math';

.p-tag {
  padding: $tagPadding;

  font-size: $badgeFontSize;
  font-weight: $badgeFontWeight;
  color: $badgeTextColor;

  background: $badgeBg;
  border-radius: 0.4rem;

  &.p-tag-success {
    color: $successButtonTextColor;
    background-color: $successButtonBg;
    border: $successButtonBorder;
  }

  &.p-tag-info {
    color: $infoButtonTextColor;
    background-color: $infoButtonBg;
    border: $infoButtonBorder;
  }

  &.p-tag-warning {
    color: $warningButtonTextColor;
    background-color: $warningButtonBg;
    border: $warningButtonBorder;
  }

  &.p-tag-danger {
    color: #F5222D;
    background-color: #FFF1F0;
    border: thin solid #FFA39E;
  }

  .p-tag-icon {
    margin-right: math.div($inlineSpacing, 2);
    font-size: $badgeFontSize;
  }

  .p-icon {
    width: $badgeFontSize;
    height: $badgeFontSize;
  }
}
