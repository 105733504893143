.p-megamenu {
    padding: $horizontalMenuPadding;

    color: $horizontalMenuTextColor;

    background: $horizontalMenuBg;
    border: $horizontalMenuBorder;
    border-radius: $borderRadius;

    .p-megamenu-root-list {  
        outline: 0 none;
    }

    .p-menuitem {
        @include menuitem;
    }

    .p-megamenu-panel {
        color: $menuTextColor;
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;

        font-weight: $submenuHeaderFontWeight;
        color: $submenuHeaderTextColor;

        background: $submenuHeaderBg; 
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius; 
    }

    .p-submenu-list {
        width: $menuWidth;
        padding: $verticalMenuPadding;

        .p-menuitem-separator {
            margin: $menuSeparatorMargin;
            border-top: $divider;
        }
    }

    &.p-megamenu-vertical {
        width: $menuWidth;
        padding: $verticalMenuPadding;
    }

    &.p-megamenu-horizontal {
        .p-megamenu-root-list {  
            > .p-menuitem {
                @include horizontal-rootmenuitem;
            }
        }
    }
}