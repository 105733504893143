.p-message {
  margin: $messageMargin;
  line-height: 1.42;
  border-radius: 0.4rem;

  .p-message-wrapper {
    padding: $messagePadding;
  }

  .p-message-close {
    width: $actionIconWidth;
    height: $actionIconHeight;

    background: transparent;
    border-radius: $actionIconBorderRadius;

    transition: $actionIconTransition;

    &:hover {
      background: rgb(255 255 255 / 30%);
    }

    &:focus {
      @include focused;
    }
  }

  &.p-message-info {
    color: $infoMessageTextColor;
    background: $infoMessageBg;
    border: $infoMessageBorder;
    border-width: $messageBorderWidth;

    .p-message-icon {
      color: $infoMessageIconColor;
    }

    .p-message-close {
      color: $infoMessageIconColor;
    }
  }

  &.p-message-success {
    color: $successMessageTextColor;
    background: $successMessageBg;
    border: $successMessageBorder;
    border-width: $messageBorderWidth;

    .p-message-icon {
      color: $successMessageIconColor;
    }

    .p-message-close {
      color: $successMessageIconColor;
    }
  }

  &.p-message-warn {
    color: $warningMessageTextColor;
    background: $warningMessageBg;
    border: $warningMessageBorder;
    border-width: $messageBorderWidth;

    .p-message-icon {
      color: $warningMessageIconColor;
    }

    .p-message-close {
      color: $warningMessageIconColor;
    }
  }

  &.p-message-error {
    color: $errorMessageTextColor;
    background: $errorMessageBg;
    border: $errorMessageBorder;
    border-width: $messageBorderWidth;

    .p-message-icon {
      color: $errorMessageIconColor;
    }

    .p-message-close {
      color: $errorMessageIconColor;
    }
  }

  .p-message-text {
    font-size: $messageTextFontSize;
    font-weight: $messageTextFontWeight;
  }

  .p-message-icon {
    margin-right: $inlineSpacing;
    font-size: $messageIconFontSize;
  }

  .p-icon {
    width: $messageIconFontSize;
    height: $messageIconFontSize;
  }

  .p-message-summary {
    font-weight: 700;
  }

  .p-message-detail {
    margin-left: $inlineSpacing;
  }
}

.p-message-no-icon {
  .p-message-icon {
    display: none;
  }

  .p-message-detail {
    margin-left: 0;
  }
}
