.p-slidemenu {
    width: $menuWidth;
    padding: $verticalMenuPadding;

    color: $menuTextColor;

    background: $menuBg;
    border: $menuBorder;
    border-radius: $borderRadius;
    
    .p-slidemenu-root-list {
        outline: 0 none;
    }

    .p-submenu-list {
        outline: 0 none;
    }

    .p-menuitem {
        @include menuitem;
    }

    &.p-slidemenu-overlay {
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
    }

    .p-slidemenu-list {
        padding: $verticalMenuPadding;
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
    }

    .p-menuitem-separator {
        margin: $menuSeparatorMargin;
        border-top: $divider;
    }

    .p-slidemenu-icon {
        font-size: $menuitemSubmenuIconFontSize;
    }

    .p-icon {
        width: $menuitemSubmenuIconFontSize;
        height: $menuitemSubmenuIconFontSize;
    }

    .p-slidemenu-backward {
        padding: $menuitemPadding;
	    color: $menuitemTextColor;

        &:not(.p-disabled):focus {
            @include focused-inset;
        }
    }

    .p-menuitem-badge {
        @include menuitem-badge;
    }
}
