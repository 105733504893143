.p-radiobutton {
    width: $radiobuttonWidth;
    height: $radiobuttonHeight;

    .p-radiobutton-box {
        width: $radiobuttonWidth;
        height: $radiobuttonHeight;

        color: $textColor;

        background: $inputBg;
        border: $radiobuttonBorder;
        border-radius: 50%;

        transition: $formElementTransition;

        &:not(.p-disabled, .p-highlight):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input;
        }

        .p-radiobutton-icon {
            width: $radiobuttonIconSize;
            height: $radiobuttonIconSize;
            background-color: $radiobuttonIconActiveColor;
            transition-duration: $transitionDuration;
        }

        &.p-highlight {
            background: $radiobuttonActiveBg;
            border-color: $radiobuttonActiveBorderColor;

            &:not(.p-disabled):hover {
                color: $radiobuttonIconActiveHoverColor;
                background: $radiobuttonActiveHoverBg;
                border-color: $radiobuttonActiveHoverBorderColor;
            }
        }
    }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    @include invalid-input;
}

.p-input-filled {
    .p-radiobutton {
        .p-radiobutton-box {
            background-color: $inputFilledBg;

            &:not(.p-disabled):hover {
                background-color: $inputFilledHoverBg;
            }

            &.p-highlight {
                background: $radiobuttonActiveBg;
    
                &:not(.p-disabled):hover {
                    background: $radiobuttonActiveHoverBg;
                }
            }
        }
    }
}

.p-radiobutton-label {
    margin-left: $inlineSpacing;
}

@if  $highlightBg == $radiobuttonActiveBg {
    .p-highlight {
        .p-radiobutton {
            .p-radiobutton-box {
                border-color: $radiobuttonIconActiveColor;
            }
        }
    }
}